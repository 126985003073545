import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppProviders from './modules/Core/state/AppContext';
import './scss/index.scss';
import * as serviceWorker from './serviceWorker';

serviceWorker.register({
    onSuccess: () => {
        window.location.reload();
    },
    onUpdate : () => {
        if (window.confirm(`New content is available!. Click OK to refresh.`)) {
            window.location.reload();
        }
    },
});


if (navigator.serviceWorker) {
    navigator.serviceWorker.ready.then((registration) => {
        ReactDOM.render(
            <AppProviders>
                <App swRegistration={registration} />
            </AppProviders>,
            document.getElementById('root')
        );
    });
} else {
    ReactDOM.render(
        <AppProviders>
            <App />
        </AppProviders>,
        document.getElementById('root')
    );
}
